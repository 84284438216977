// extracted by mini-css-extract-plugin
export var achievementAction = "technologies-module--achievementAction--JSwFf";
export var achievementContent = "technologies-module--achievementContent--+R-dT";
export var achievementItem = "technologies-module--achievementItem--qFge1";
export var achievementList = "technologies-module--achievementList--VL0Vh";
export var achievementReason = "technologies-module--achievementReason--+Abiq";
export var achievementSection = "technologies-module--achievementSection--JOC-d";
export var introContent = "technologies-module--introContent--gy3RF";
export var introSection = "technologies-module--introSection--KB3GY";
export var introText = "technologies-module--introText--2DfDy";
export var introTitle = "technologies-module--introTitle--BhLMH";
export var joinUsDescription = "technologies-module--joinUsDescription--GKO8h";
export var joinUsSection = "technologies-module--joinUsSection--7UTjo";
export var joinUsTitle = "technologies-module--joinUsTitle--qQAKe";
export var productItemText = "technologies-module--productItemText--Yf0+-";
export var productItemTitle = "technologies-module--productItemTitle--Li0x2";
export var productItemWrapper = "technologies-module--productItemWrapper--A52X6";
export var productList = "technologies-module--productList--CVGFk";
export var productListItem = "technologies-module--productListItem--jb2-z";
export var productListItemReverse = "technologies-module--productListItemReverse--Ohytf";
export var productSection = "technologies-module--productSection--OXEA+";
export var productTitle = "technologies-module--productTitle--fFK+i";
export var teamSection = "technologies-module--teamSection--rHAy6";
export var teamSectionImg = "technologies-module--teamSectionImg--u-J-C";
export var teamSectionItem = "technologies-module--teamSectionItem--9gcnQ";
export var teamSectionList = "technologies-module--teamSectionList--JZqhN";
export var teamSectionPhoto = "technologies-module--teamSectionPhoto--9Rm80";
export var technologiesList = "technologies-module--technologiesList--BWEJN";
export var technologiesListItem = "technologies-module--technologiesListItem--XtbyH";
export var technologiesSection = "technologies-module--technologiesSection--dQqc-";