// i18next-extract-mark-ns-start technologies-page

import React, { FC } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import cn from "classnames";

import { useWindowSize } from "@/hooks";
import { CareerBlock } from "@/components/ui";
import { Layout } from "@/components/layout";
import VerifyEmailsIllustr from "@/assets/svgs/technologies/verify-emails-illust.svg";
import ValidLeadsIllustr from "@/assets/svgs/technologies/valid-leads-illustr.svg";
import TechnologiesIllstr from "@/assets/svgs/technologies/technologies-illstr.svg";
import SalesforceIcon from "@/assets/svgs/technologies/icons/salesforce.svg";
import RustIcon from "@/assets/svgs/technologies/icons/rust.svg";
import RestApiIcon from "@/assets/svgs/technologies/icons/rest-api.svg";
import ReactIcon from "@/assets/svgs/technologies/icons/react-icon.svg";
import NodeJsIcon from "@/assets/svgs/technologies/icons/node-js.svg";
import JiraIcon from "@/assets/svgs/technologies/icons/jira.svg";
import JavaScriptIcon from "@/assets/svgs/technologies/icons/java-script.svg";
import GoogleCloudIcon from "@/assets/svgs/technologies/icons/google-cloud.svg";
import GoIcon from "@/assets/svgs/technologies/icons/go.svg";
import GitLabIcon from "@/assets/svgs/technologies/icons/git-lab.svg";
import ConfluenceIcon from "@/assets/svgs/technologies/icons/confluence.svg";
import AwsIcon from "@/assets/svgs/technologies/icons/aws.svg";
import IncreaseClickabilityIllstr from "@/assets/svgs/increase-clickability-illstr.svg";

import * as styles from "./technologies.module.css";

const productIllustrations = [<IncreaseClickabilityIllstr />, <VerifyEmailsIllustr />, <ValidLeadsIllustr />];
const technologiesIcons = [
  <JavaScriptIcon />,
  <ReactIcon />,
  <RestApiIcon />,
  <NodeJsIcon />,
  <RustIcon />,
  <AwsIcon />,
  <ConfluenceIcon />,
  <GoIcon />,
  <GitLabIcon />,
  <SalesforceIcon />,
  <JiraIcon />,
  <GoogleCloudIcon />,
];

type TechnologiesPageProps = {
  data: {
    team1: FileNode;
    team2: FileNode;
    team3: FileNode;
    team4: FileNode;
    team4Mobile: FileNode;
    team5: FileNode;
    team5Tablet: FileNode;
  };
};

const TechnologiesPage: FC<TechnologiesPageProps> = ({ data }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const isMobile = width < 640;
  const isTablet = width < 950;
  const productList = t("technologies.product_section.list", { returnObjects: true });
  const achievementList = t("technologies.achievement_section.list", { returnObjects: true });

  return (
    <Layout>
      <section className={styles.introSection}>
        <div className={styles.introContent}>
          <h1 className={styles.introTitle}>{t("technologies.intro_section.title")}</h1>
          <p className={styles.introText}>{t("technologies.intro_section.description")}</p>
        </div>
        <TechnologiesIllstr />
      </section>
      <section className={styles.technologiesSection}>
        <ul className={styles.technologiesList}>
          {technologiesIcons.map((icon) => (
            <li className={styles.technologiesListItem}>{icon}</li>
          ))}
        </ul>
      </section>
      <section className={styles.productSection}>
        <h2 className={styles.productTitle}>{t("technologies.product_section.title")}</h2>
        <ul className={styles.productList}>
          {productList.map(({ title, description }, index) => {
            const cls = cn(styles.productListItem, { [styles.productListItemReverse]: index % 2 !== 0 });

            return (
              <li className={cls} key={title}>
                {productIllustrations[index]}
                <div className={styles.productItemWrapper}>
                  <h3 className={styles.productItemTitle}>{title}</h3>
                  <p className={styles.productItemText}>{description}</p>
                </div>
              </li>
            );
          })}
        </ul>
      </section>
      <section className={styles.achievementSection}>
        <ul className={styles.achievementList}>
          {achievementList.map(({ action, content, reason }) => (
            <li className={styles.achievementItem} key={reason}>
              <h4 className={styles.achievementAction}>{action}</h4>
              <p className={styles.achievementContent}>{content}</p>
              <h3 className={styles.achievementReason}>{reason}</h3>
            </li>
          ))}
        </ul>
      </section>
      <section className={styles.joinUsSection}>
        <h2 className={styles.joinUsTitle}>{t("technologies.join_us_section.title")}</h2>
        <p className={styles.joinUsDescription}>{t("technologies.join_us_section.description")}</p>
      </section>
      <section className={styles.teamSection}>
        <ul className={styles.teamSectionList}>
          <li className={styles.teamSectionItem}>
            <GatsbyImage
              className={styles.teamSectionPhoto}
              imgClassName={styles.teamSectionImg}
              image={getImage(data.team1)}
              alt=""
            />
          </li>
          <li className={styles.teamSectionItem}>
            <GatsbyImage className={styles.teamSectionPhoto} image={getImage(data.team2)} alt="" />
          </li>
          <li className={styles.teamSectionItem}>
            <GatsbyImage className={styles.teamSectionPhoto} image={getImage(data.team3)} alt="" />
          </li>
          <li className={styles.teamSectionItem}>
            <GatsbyImage
              className={styles.teamSectionPhoto}
              image={getImage(isMobile ? data.team4Mobile : data.team4)}
              alt=""
            />
          </li>
          <li className={styles.teamSectionItem}>
            <GatsbyImage
              className={styles.teamSectionPhoto}
              image={getImage(isTablet ? data.team5Tablet : data.team5)}
              alt=""
            />
          </li>
        </ul>
      </section>
      <CareerBlock />
    </Layout>
  );
};

export default TechnologiesPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "technologies-page"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    team1: file(relativePath: { eq: "technologies/team-photo-1.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 275, height: 400)
      }
    }
    team2: file(relativePath: { eq: "technologies/team-photo-2.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 275, height: 400)
      }
    }
    team3: file(relativePath: { eq: "technologies/team-photo-3.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 275, height: 400)
      }
    }
    team4: file(relativePath: { eq: "technologies/team-photo-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 275, height: 400)
      }
    }
    team4Mobile: file(relativePath: { eq: "technologies/team-photo-4.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300, height: 200)
      }
    }
    team5: file(relativePath: { eq: "technologies/team-photo-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 275, height: 400)
      }
    }
    team5Tablet: file(relativePath: { eq: "technologies/team-photo-5.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 688, height: 461)
      }
    }
  }
`;
